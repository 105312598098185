import React, { useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import Signin from './Signin'



function TopHeader() {
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.clear();
        navigate("/")
    }

    // const [isOpen, setIsOpen] = useState(false);
    const token = localStorage.getItem('ID')

    return (
        <>
            <div className="top-header2 " style={{ background: "linear-gradient(rgb(249 162 4), rgb(225, 114, 0))" }}>
                <div className="custom-container">
                    <div className="row">
                        <div className="col-md-8 col-sm-12">
                            <div className="top-header-left">
                                <ul>
                                    <li>
                                        <a href="#">
                                            Organic Deals
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i className="fa fa-phone" />
                                            Call Us: 9716412565/8826302378
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="top-header-right">
                                <ul>

                                    <li>
                                        <Link to="/wishlist">
                                            <i className="fa fa-heart" /> wishlist
                                        </Link>
                                    </li>
                                    {!token &&
                                        <li>
                                            <Link to="/login" >
                                                <i className="fa fa-user" /> Login/Signup
                                            </Link>

                                        </li>
                                    }

                                    {token &&
                                        <>

                                            <li onClick={handleLogout} >
                                                <button >
                                                    <i className="fa fa-user" /> Logout
                                                </button>

                                            </li>
                                        </>
                                    }
                                </ul>

                            </div>
                        </div>

                    </div>
                </div>
            </div>




            <>

                <div
                    className="offcanvas offcanvas-end"
                    tabIndex={-1}
                    id="offcanvasRight"
                    aria-labelledby="offcanvasRightLabel"
                >
                    <div className="offcanvas-header" style={{ background: "linear-gradient(rgb(249 162 4), rgb(225, 114, 0))" }}>
                        <h5 id="offcanvasRightLabel" className='text-white font-bold text-xl'>My Account</h5>
                        <button
                            type="button"
                            className="btn-close text-reset"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                        ><p className='text-white font-bold text-xl'>X</p></button>
                    </div>
                    <div className="offcanvas-body">
                        <Signin />

                    </div>
                </div>
            </>


        </>
    )
}

export default TopHeader
